export const dateFormat = function (dateString, showTime) {
	if (dateString) {
		let newDate = new Date(dateString);
		let dataArray = newDate.toString().split(' ');
		if (showTime) {
			return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
		}
		return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}`;
	}
};
export const capitalize = (s) => {
	return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
};

export const getFrequencyUnit = (unit) => {
	if (unit === 'HOUR') return 'an hour';
	else return `a ${unit}`;
};

export const validUrl = (url) => {
	const httpsRegex= /^https:\/\//i;
    if(typeof url === 'string' && httpsRegex.test(url)){
		return url;
	}
}

export const applyFontVariants = (fontURL) => {
	// checking if valid URL or not
	if(validUrl(fontURL)){
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = fontURL;
		link.type = 'text/css'; 
	
		//appending the element in the head 
		document.head.appendChild(link);
	}
} 

export const getConsentModeText = (mode, value, unit) => {
	let pastTense;

	switch (mode) {
		case 'STORE':
			pastTense = 'Stored';
			break;
		case 'VIEW':
			pastTense = 'Viewed';
			break;
		case 'QUERY':
			pastTense = 'Queried';
			break;
		case 'STREAM':
			pastTense = 'Streamed';
			break;
		default:
			return 'Invalid mode';
	}

	if (unit === 'INF') {
		return `${pastTense} till INF`;
	}

	let endTime;

	switch (unit) {
		case 'MONTH':
			endTime = new Date(
				Date.now() + parseInt(value) * 30 * 24 * 60 * 60 * 1000
			);
			break;
		case 'YEAR':
			endTime = new Date(
				Date.now() + parseInt(value) * 365 * 24 * 60 * 60 * 1000
			);
			break;
		case 'DAY':
			endTime = new Date(Date.now() + parseInt(value) * 24 * 60 * 60 * 1000);
			break;
		default:
			return 'Invalid unit';
	}

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const day = endTime.getDate();
	const month = months[endTime.getMonth()];
	const year = endTime.getFullYear();

	return `${pastTense} till ${day} ${month} ${year}`;
};

const fiTyes = {
	'AIF': 'AIF',
	'CIS': 'CIS',
	'DEPOSIT': 'Bank Accounts',
	'EQUITIES': 'Equities',
	'ETF': 'ETF',
	'GSTR1_3B': 'GSTN',
	'IDR': 'IDR',
	'INSURANCE_POLICIES': 'Insurance Policies',
	'INVIT': 'INVIT',
	'MUTUAL_FUNDS': 'Mutual Funds',
	'NPS': 'NPS',
	'RECURRING_DEPOSIT': 'Recurring Deposit',
	'REIT': 'REIT',
	'SIP': 'SIP',
	'TERM-DEPOSIT': 'Term Deposit',
};

export const transformFiType = (s) => {
	if (Object.keys(fiTyes).includes(s)) {
		return fiTyes[s];
	}
	return s.toUpperCase() && s[0].toUpperCase() + s.slice(1).toLowerCase();
};

export const acInfoTransform = (el, infoType, i) => {
	return (
		<span key={i}>
			{transformFiType(el).split('_').join(' ')}
			{infoType?.length - 1 === i ? '' : ', '}
		</span>
	);
};
const phoneNumberRegex = /^[0-9]{10}$/;

export const isPhoneNumberValid = (yourPhoneNumber) => {
	// Remove '+' from the input value
	return phoneNumberRegex.test(yourPhoneNumber);
};

export const getFiTypesHash = (fiTypes) => {
	return fiTypes.sort().join('|');
};

export const getConsentTitle = (num) => {
	// console.log(num);
	if (num > 1) {
		return `${num} Consents Requested`;
	} else return `Consent Requested`;
};

export const areAllArraysEmptyForConsents = (obj, consentHandles) => {
	for (const consentHandle of consentHandles) {
		if (!Array.isArray(obj[consentHandle]) || obj[consentHandle].length > 0) {
			// If the value associated with the consent handle is not an empty array, return false
			return false;
		}
	}
	// If all consent handles have empty arrays, return true
	return true;
};


