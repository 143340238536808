import { Box, Button } from '@mui/material'
import React from 'react'
import sizeFontCustom from "../Discovery/fontSize.styles"

function CancelConsent({handleCancel, handleDeny}) {
  return (
        <Box sx={{ display: { xs: "flex" } }}>
          <Button
            variant='text' 
            color='error' 
            onClick={handleDeny}
            sx={{
              width: "80%",
              height: "2rem",
              textTransform: "none",
              fontWeight: 600,
              ...sizeFontCustom.heading
            }}
          >
            Yes, exit
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{
              width: "80%",
              height: "2rem",
              textTransform: "none",
              fontWeight: 600,
              ...sizeFontCustom.heading

            }}
          >
            Go Back
          </Button>
          
    </Box>  )
}

export default CancelConsent