import {
	Box,
	Button,
	Grid,
	IconButton,
	Link,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FrequentFip from './FrequentFip';
import { useContext, useState } from 'react';
import SearchFip from './SearchFip';
import WhyShare from '../Sections/WhyShare';
import linkedAccountsSectionStyles from '../Accounts/LinkedAccountsSection.styles';
import { topFipList as topFips } from '../../assets/topFipList';
import addAccountStyles from './AddAccount.styles';
import ConsentListContext from '../Context/ConsentContext';
function AddAccount(props) {
	const { popularFipList, isNewUser } = props;
	const consentsContext = useContext(ConsentListContext);
  const consentsAll = consentsContext.consentList;
  const fiTypesAllConsents = []

  consentsAll.forEach(consent=>fiTypesAllConsents.push(...consent.fiTypes))
	const fiSelected = props.history.location.state?.fiTypes
		? props.history.location.state?.fiTypes
		: fiTypesAllConsents;

	const popularFipListFiltered = fiSelected
		? popularFipList?.filter((x) =>
				x?.fipFitypes?.some((fi) => fiSelected?.includes(fi))
		  )
		: popularFipList;

	const topFipList = popularFipListFiltered
		?.filter((x) => topFips?.includes(x?.fipId))
		?.map((x) => x?.fipId);

	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [topFip, setTopFip] = useState(topFipList);
  
	const otherFip = popularFipListFiltered.filter(
		(x) => !topFip.includes(x.fipId)
	);
	const [searchResults, setSearchResults] = useState(otherFip);
	const [selectedFipList, setSelectedFipList] = useState([]);
	const handleSearch = (value) => {
		let results = otherFip.filter((Fip) =>
			Fip?.productName.toUpperCase().includes(value.toUpperCase())
		);
		setSearchResults(results);
	};

	let institution = 'institution';
	switch (fiSelected) {
		case 'DEPOSIT':
		case 'TERM-DEPOSIT':
		case 'RECURRING_DEPOSIT':
			institution = 'Banks';
			break;
		case 'INSURANCE_POLICIES':
			institution = 'Insurance Companies';
			break;
		case 'MUTUAL_FUNDS':
		case 'EQUITIES':
			institution = 'Mutual Fund Companies';
			break;
		case 'GSTR1_3B':
			break;
		default:
			break;
	}

	const handleCheckboxChange = (event, fipId) => {
		const isChecked = event.target.checked;
		if (global?.allowMultipleFipDiscovery !== 'true') {
			return props.goToAccounts(fipId);
		}
		if (isChecked) {
			setSelectedFipList((prevSelected) => [...prevSelected, fipId]);
		} else {
			setSelectedFipList((prevSelected) =>
				prevSelected.filter((id) => id !== fipId)
			);
			setTopFip((prevSelected) =>
				prevSelected.filter((id) =>
					!topFipList.includes(id) ? id !== fipId : true
				)
			);
			setSearchResults((prevSearchResults) => [
				...prevSearchResults,
				...popularFipListFiltered.filter(
					(fip) => fip.fipId === fipId && !topFipList.includes(fip.fipId)
				),
			]);
		}
	};

	const handleOtherFipCheckboxChange = (event, fip) => {
		window.finvuClient.clearStack()
		const isChecked = event.target.checked;
		handleCheckboxChange(event, fip.fipId);
		if (isChecked) {
			setTopFip((prevSelected) => [...prevSelected, fip.fipId]);
			setSearchResults((prevSearchResults) =>
				prevSearchResults.filter((fipSearch) => fipSearch.fipId !== fip.fipId)
			);
		} else {
			setTopFip((prevSelected) =>
				prevSelected.filter((id) => id !== fip.fipId)
			);
		}
	};

	return (
		<>
			<Box
				sx={{
					minWidth: mobileScreen ? '100vw' : 'auto',
					padding: mobileScreen
						? '2.25rem 1.5rem 5.5rem 1.5rem'
						: '6rem 1.875rem',
					backgroundColor: theme.palette.primary.light,
					gap: mobileScreen ? '1.25rem' : '1.5rem',
					...addAccountStyles.container,
				}}
			>
				<Box width={'100%'}>
					<Box sx={addAccountStyles.flexRowSb}>
						<Box sx={addAccountStyles.flexRowC}>
							{!mobileScreen && global.isAddAccount ? (
								<IconButton
									disableRipple
									sx={{ pl: 0 }}
									onClick={() => props.history.push(`/payment-consent`)}
								>
									<ArrowBackIcon sx={{ color: theme.palette.primary.main }} />
								</IconButton>
							) : null}
							<Typography sx={addAccountStyles.title}>
								Add New Account
							</Typography>
						</Box>
						{!mobileScreen && global?.allowMultipleFipDiscovery === 'true' && (
							<Button
								size="large"
								variant="contained"
								onClick={() => {
									window.finvuClient.clearStack()
									props.goToAccounts(selectedFipList);
								}}
								disabled={selectedFipList.length === 0}
								sx={{
									textTransform: 'capitalize',
									width: '15%',
									backgroundColor: props.customDetails.primaryColor,
								}}
							>
								Next
							</Button>
						)}
					</Box>
					<Typography
						color={theme.palette.black60}
						sx={addAccountStyles.subTitle}
					>
						Select institution for adding your accounts associated with your
						mobile number{' '}
						<span
							style={{
								color: theme.palette.info.main,
								fontWeight: '700',
							}}
						>
							{global.mobileNumber
								? global.mobileNumber
								: global.mobileNumberArray[0]}{' '}
							{'. '}{' '}
						</span>
						{global.sdkOptions?.allowDifferentMobile === 'Y' && (
							<Link
								color={theme.palette.primary.main}
								onClick={() => {
									props.history.push(
										`/NoAccountFound/${global.mobileNumber}?step=${101}&fip=${
											props.match.params.fid
										}`
									);
								}}
								sx={addAccountStyles.changeNumberLink}
							>
								CHANGE MOBILE NUMBER.
								<br />
							</Link>
						)}
						Information from your account will be shared. Banks are not
						supporting joint accounts.
					</Typography>
				</Box>
				{!mobileScreen&&(<Box
                    sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'white',
                    padding: '2rem',
                    zIndex:6,
                    }}
                >
                    <WhyShare {...props}/>
                </Box>)}
				<Grid
					container
					display={topFip.length > 0 ? 'flex' : 'none'}
					sx={addAccountStyles.topFipContainer}
				>
					{topFip.map((fipId) =>
						props.customDetails?.excludeFIP ? (
							!props.customDetails?.excludeFIP.includes(fipId) && (
								<FrequentFip
									key={fipId}
									selectedFipList={selectedFipList}
									handleCheckboxChange={handleCheckboxChange}
									{...props}
									fipId={fipId}
									popular={true}
								/>
							)
						) : (
							<FrequentFip
								key={fipId}
								selectedFipList={selectedFipList}
								handleCheckboxChange={handleCheckboxChange}
								{...props}
								fipId={fipId}
								popular={true}
							/>
						)
					)}
				</Grid>
				<SearchFip
					handleOtherFipCheckboxChange={handleOtherFipCheckboxChange}
					searchResults={searchResults}
					handleSearch={handleSearch}
					topFip={topFip}
					{...props}
				/>
			</Box>

			{mobileScreen ? (
				<Box
					sx={{
						bottom: mobileScreen ? '0rem' : '1.875rem',
						right: mobileScreen ? '0rem' : '0rem',
						backgroundColor: mobileScreen ? 'white' : 'none',
						gap: mobileScreen ? '1rem' : '1.25rem',
						width: mobileScreen ? '100vw' : '20%',
						flexWrap: 'wrap',
						...linkedAccountsSectionStyles.buttonGroup,
					}}
				>
					<Button
						variant="outlined"
						fullWidth={mobileScreen}
						color="primary"
						onClick={() => props.history.push(`/payment-consent`)}
						sx={{ flex: 1, background: theme.palette.primary.white }}
					>
						Back
					</Button>
					{global?.allowMultipleFipDiscovery === 'true' && (
						<Button
							variant="contained"
							disabled={selectedFipList.length === 0}
							fullWidth={mobileScreen}
							onClick={() => {
								window.finvuClient.clearStack()
								props.goToAccounts(selectedFipList);
							}}
							sx={{
								backgroundColor: props.customDetails.primaryColor,
								flex: 1,
							}}
						>
							Next
						</Button>
					)}
					<WhyShare {...props} />
				</Box>
			) : null}
		</>
	);
}

export default AddAccount;
