const headerStyles = {
  container: { flexGrow: 1 },
  appBar: { position: "fixed", background: "#FFFFFF" },
  p0: { padding: "0!important" },
  imageBox: {
    display: "flex",
    justifyContent:"space-between",
    alignItems: "center",
    margin: "1.15rem 1.875rem 1.15rem 0.75rem",
    width: "100vw"
  },
  logoContainer: {
    maxWidth: "50%",
    display: "flex",
    justifyContent: "center",
    maxHeight: "100%"
  },
  logo: { display: "block", objectFit: "contain", maxWidth: "100%",maxHeight: "2.5rem"},
  cancelIcon :{
    padding: 0, 
    color:'black',
    backgroundColor:'white',
    boxShadow: 4,
    p:'0.25rem',
  },
  cancelIconFont: {fontSize : "0.85rem"}
}

export default headerStyles;