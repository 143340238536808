import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import SetTheme from './common/SetTheme';
import AddAccount from './AddAccount/AddAccount';
import ConsentListContext from './Context/ConsentContext';
export default class Home extends Component {
  static contextType = ConsentListContext

  constructor(props) {
    
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    global.isAddAccount = queryParams.get('isAddAccount') ? true : false
    this.isNewUser = queryParams.get("isNewUser") ? true: false
    this.state = {
      populerFipList: global.populerFips?.length ? global.populerFips : [],
      selectedNumber: global.mobileNumber ? global.mobileNumber : global.mobileNumberArray[0],
      consentDetailsList: global.consentList ? global.consentList : [],
      pan: "",
      dob: ""
    }
    this.authApis = new AuthApis()
    if (queryParams.get('number')) {
      this.number = queryParams.get('number')
    }
    else {
      this.number = global.mobileNumber
    }
  }
  componentDidMount() {
    // this.consentRequestDetails();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.history.action === 'POP') {
        window.confirm("Are you sure to go back ? Consent will not be actioned.")
      }
    }
  }
  consentRequestDetails = () => {
    if (this.state.consentDetailsList.length === 0) {
      this.authApis.consentRequestDetails().then(res => {
        this.props.updateLoader(false)
        if (res.status === 'SUCCESS') {
          const validConsents = res.consentRequestList.filter(el => el.status === "SUCCESS")
          this.setState({ consentDetailsList: validConsents })
          global.consent = this.state.consentDetailsList?.length > 0 ? this.state.consentDetailsList[0] : validConsents[0]
        } else {
          toast.error(res.message)
          // this.props.history.push(`/error`)
          if (res.status === "FAILURE") {
            postMessage('session', 'FAILURE')
          } else if (res.status === "ERROR") {
            postMessage('session', 'ERROR')
          }
          setTimeout(() => {
            this.props.history.push({
              pathname: `/consent-status`,
              state: {
                statusCode: 20,
              }
            });
          }, 3000)

        }
      })
    }
  }
  goToAccounts = (fid) => {
    this.props.history.push(`/accounts/${this.state.selectedNumber}/${fid}`)
  }
  setTheme = theme => {
    this.setState({ theme })
  }
  render() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "start", md: "center" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          minHeight: '100vh',
          alignItems: "start",
        }}>
        <SetTheme customDetails={this.props.customDetails} setTheme={this.setTheme} />
        <AddAccount goToAccounts={this.goToAccounts} popularFipList={this.state.populerFipList} isNewUser={this.isNewUser} {...this.props} />
      </Box>

    )
  }
}
