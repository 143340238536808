import React from 'react';
import ReactDOM from 'react-dom/client'; 
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import { ThemeProvider } from './ThemeProviderWrapper';
import ScrollToTop from './components/common/ScrollToTop';

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(
  <ThemeProvider defTheme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <ScrollToTop />
      <Route path="/" component={App} />
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

